import type { TransferSchedule } from '@getstep/sdk/dist/api/generated/TransferProto'

export enum FundingStage {
    Amount = 'amount',
    Complete = 'complete',
    Expired = 'expired',
    Fail = 'fail',
    Input = 'input',
    Pending = 'pending',
    Processing = 'processing',
    Review = 'review',
    Success = 'success',
}

export type FundingAmountFormValues = {
    amount?: number
    note?: string
    frequency?: string
    interval?: string
    schedule?: TransferSchedule
}

export type FundingCardFormValues = {
    cardToken?: any
    zipPostalCode?: string
}

export const userIdToParam = (userId: string) => {
    return userId.split(':').pop()
}

export const paramToUserId = (param: string) => {
    return `u:${param}`
}
