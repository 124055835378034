import classNames from 'classnames'
import type { FunctionComponent } from 'mdx/types'
import type { PropsWithChildren } from 'react'

import styles from './responsive.module.scss'

export const Desktop: FunctionComponent<ResponsiveProps> = ({
    className,
    children,
    visible = true,
}) => {
    if (!visible) return null

    return (
        <div className={classNames(styles.desktop, className)}>{children}</div>
    )
}

export const Mobile: FunctionComponent<ResponsiveProps> = ({
    className,
    children,
    visible = true,
}) => {
    if (!visible) return null

    return (
        <div className={classNames(styles.mobile, className)}>{children}</div>
    )
}

interface ResponsiveProps extends PropsWithChildren {
    className?: string
    visible?: boolean
}
