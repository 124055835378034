import { DownloadApp } from '../../../../../contentful-components/forms/download-app'
import { TEXT } from '../../../../../lib/utils/text'
import { GetStepButton } from '../../../../get-step-button'
import { Desktop, Mobile } from '../../../../responsive'

export const DownloadStepAction = () => {
    return (
        <>
            <Mobile>
                <GetStepButton size='small' />
            </Mobile>
            <Desktop>
                <DownloadApp
                    analyticsKey='getApp.button'
                    placement='header'
                    title={TEXT.getStarted}
                    size='medium'
                    preset='futureFuscia'
                />
            </Desktop>
        </>
    )
}
