import identity from 'lodash/identity'
import { configure } from 'mobx'
import { enableStaticRendering } from 'mobx-react'
import type { AppContext, AppProps } from 'next/app'
import BaseApp from 'next/app'
import 'normalize.css/normalize.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import React, { useEffect, useState } from 'react'
import prepass from 'react-ssr-prepass'
import { Provider } from 'urql'
import '@fontsource-variable/inter'

import { Layout } from '../components/layout'
import { DefaultMeta } from '../contentful-components/page-meta/default-meta'
import type { ContentfulClient } from '../graphql/client'
import { getOrCreateContentfulClient } from '../graphql/client'
import { PageError } from '../lib/errors/PageError'
import { useCookieConsent } from '../lib/hooks/useCookieConsent'
import { useFirstInteraction } from '../lib/hooks/useFirstInteraction'
import { Intercom } from '../lib/intercom'
import type { RootStoreState } from '../lib/store/RootStore'
import { RootStore } from '../lib/store/RootStore'
import { tracker } from '../lib/store/tracker/useTracker'
import { RootStoreProvider } from '../lib/store/useStores'
import { UIContextProvider } from '../lib/store/useUIStore'
import type { PageWithContext } from '../lib/utils/context'
import { isDocumentContext } from '../lib/utils/context'
import { getOrConfigureTeamEnvironment } from '../lib/utils/team-env'
import { isBrowser } from '../shared/util/env'
import '../styles/index.scss'

// TODO (@cataline): Remove this
configure({ enforceActions: 'never' })

NProgress.configure({ showSpinner: false })

// Suppress layoutEffect warnings for SSR
if (!isBrowser()) {
    React.useLayoutEffect = React.useEffect
    enableStaticRendering(true)
}

const App = ({
    Component,
    pageProps,
    rootStore,
    graphQLClient,
    router,
    asPath,
}: Props) => {
    useFirstInteraction(Intercom.boot)

    useEffect(() => {
        router.events.on('routeChangeStart', () => NProgress.start())
        router.events.on('routeChangeComplete', () => NProgress.done())
        router.events.on('routeChangeError', () => NProgress.done())
    }, [router])

    useCookieConsent((consent) => {
        // check if we have consent to use analytics trackers
        const hasAnalyticsConsent =
            consent?.statistics === true &&
            window?.Cookiebot?.doNotTrack === false

        tracker.setConsent(hasAnalyticsConsent)

        if (hasAnalyticsConsent) {
            window?.dataLayer?.push({
                event: 'cookie_consent_updated',
            })
        }
    })

    const [graphql] = useState(() =>
        graphQLClient && 'ssr' in graphQLClient
            ? graphQLClient
            : getOrCreateContentfulClient(router.asPath, graphQLClient)
    )

    const getLayout = Component.getLayout ? Component.getLayout : identity

    return (
        <RootStoreProvider value={rootStore}>
            {/* To make useQuery hook work, we need to pass graphQLClient to the graphqlProvider. */}
            <Provider value={graphql}>
                <UIContextProvider>
                    <DefaultMeta />
                    <Layout
                        asPath={asPath}
                        theme={pageProps?.data?.theme}
                        announcement={pageProps?.data?.announcement}
                        disclaimers={
                            pageProps?.data?.disclaimers ??
                            pageProps?.article?.disclaimers
                        }
                    >
                        {getLayout(<Component {...pageProps} />, pageProps)}
                    </Layout>
                </UIContextProvider>
            </Provider>
        </RootStoreProvider>
    )
}

App.getInitialProps = async (context: AppContext) => {
    const pageContext = context.ctx

    // if this is run within getServerSideProps, or API requests
    // we don't need to render anything and can just return
    if (!isDocumentContext(pageContext)) {
        return { props: {} }
    }

    const { AppTree, req, res, query } = pageContext
    const { router } = context
    const asPath = req?.url ?? router.asPath

    const teamEnvironment = getOrConfigureTeamEnvironment(pageContext)

    const graphQLClient = getOrCreateContentfulClient(asPath)

    const rootStore = req
        ? RootStore.fromRequest(req, teamEnvironment)
        : RootStore.fromState({})

    const props = { pageProps: {}, rootStore, graphQLClient, asPath }

    await PageError.try({ res, query, router }, async () => {
        // this executes all the .getInitialProps() of the pages in the app
        Object.assign(props, await BaseApp.getInitialProps(context))
    })

    if (res) {
        const appTreeProps =
            'Component' in context ? props : { pageProps: props }

        // Runs all GraphQL queries in the component tree. Yep, this is a duplicate of the rendered tree. Yikes!
        // But, there isn't currently another way to plug into Next.js SSR during the rendering stage :(
        await prepass(<AppTree {...appTreeProps} />)
        const initialState = graphQLClient.ssr.extractData()
        return { ...props, graphQLClient: initialState }
    }

    return props
}

type Props = AppProps & {
    Component: PageWithContext
    rootStore: RootStore | RootStoreState
    graphQLClient: ContentfulClient | object
    asPath: string
}

export default App
