import classNames from 'classnames'
import type { FunctionComponent, PropsWithChildren } from 'react'
import { memo, useEffect, useState } from 'react'
import seedrandom from 'seedrandom'

import packageJSON from '../../../package.json'
import { useDidMount } from '../../lib/hooks/useDidMount'
import styles from './skeleton.module.scss'

const random = seedrandom(packageJSON.version)

/** Displays content skeleton for awesome loading experience. */
export const Skeleton: FunctionComponent<PropsWithChildren<Props>> = memo(
    ({
        width: widthFromProps = 'random',
        height,
        tag: Tag = 'div',
        className,
        ...restOfProps
    }) => {
        const [width, setWidth] = useState(() =>
            widthFromProps === 'random' ? '0px' : widthFromProps
        )

        const mounted = useDidMount()
        useEffect(() => {
            if (widthFromProps === 'random') setWidth(randomWidth())
        }, [widthFromProps])

        if (!mounted && widthFromProps === 'random') return null

        return (
            <Tag
                aria-busy
                title='Loading…'
                data-testid='progress.wrapper'
                className={classNames(styles.skeleton, className)}
                style={{ width, height }}
                {...restOfProps}
            />
        )
    }
)

export function randomWidth(min = 30, max = 90): string {
    const int = random() * (max - min) + min
    return `${int}%`
}

interface Props {
    /** Appends this className to the rest of classNames. Does not overwrite. */
    className?: string
    /** Accepts usual formats. 10px, 100%, etc. Random sets the width randomly. Surprise! */
    width?: 'random' | string
    /** Defaults to font height. */
    height?: string
    /** The <tag to render in DOM. */
    tag?: 'div' | 'p'
}
