import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useRef } from 'react'

import { CloseIcon, OpenIcon } from '../svgs'
import styles from './hamburger-button.module.scss'

export const HamburgerButton: FunctionComponent<Props> = ({
    open,
    onClick,
}) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null)

    return (
        <button
            data-testid='header.mobile.navToggle'
            ref={buttonRef}
            aria-expanded={open}
            aria-controls='menu'
            className={classNames(
                styles.button,
                open && styles['button-active']
            )}
            onClick={onClick}
        >
            {open ? (
                <CloseIcon className={styles['close-icon']} />
            ) : (
                <OpenIcon className={styles['open-icon']} />
            )}
        </button>
    )
}

interface Props {
    open: boolean
    onClick: () => void
}
