import classNames from 'classnames'
import times from 'lodash/times'
import type {
    FunctionComponent,
    HTMLAttributes,
    PropsWithChildren,
    ReactElement,
} from 'react'

import type { ImageType } from '../../types/image'
import { Image } from '../image'
import { Skeleton } from '../skeleton'
import styles from './list.module.scss'

export enum ListIconSize {
    small = 'small',
    medium = 'medium',
    heading = 'heading',
}

/** Displays an (un)ordered list. Accepts all the usual props of `<ul>` and `<ol>`. */
export const List = ({
    loading,
    children,
    ordered,
    className,
    withCustomIcons,
    ...restOfProps
}: PropsWithChildren<Props>): ReactElement => {
    const finalClassName = classNames(styles.list, className, {
        [styles.ordered]: ordered,
        [styles['with-custom-icons']]: withCustomIcons,
    })

    const Tag = ordered ? 'ol' : 'ul'

    return (
        <Tag className={finalClassName} {...restOfProps}>
            {loading
                ? times(10, (t) => (
                      <li className={styles.item} key={t}>
                          <Skeleton>&nbsp;</Skeleton>
                      </li>
                  ))
                : children}
        </Tag>
    )
}

const Item: FunctionComponent<
    PropsWithChildren<HTMLAttributes<HTMLLIElement> & { icon?: ImageType }>
> = ({ className, children, icon, ...restOfProps }) => (
    <li
        className={classNames(styles.item, className, {
            [styles['item-with-icon']]: icon,
        })}
        {...restOfProps}
    >
        {icon && (
            <Image
                fit='contain'
                source={icon.source}
                source2x={icon.source2x}
                source3x={icon.source3x}
                contentType={icon.contentType}
                alt={icon.alt}
                className={styles.icon}
            />
        )}
        {children}
    </li>
)

List.Item = Item

interface Props extends HTMLAttributes<HTMLOListElement | HTMLUListElement> {
    /** If set to true, this will unset list-style. */
    withCustomIcons?: boolean
    /** If set to true, will render a skeleton. */
    loading?: boolean
    /** If set to true, will render an <ol> with appropriate styling. */
    ordered?: boolean
    /** Extra classname for extra special cases. */
    className?: string
}
