import classNames from 'classnames'
import type { AnchorHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { tracker } from '../../lib/store/tracker/useTracker'
import { useStores } from '../../lib/store/useStores'
import { ariaAttributes } from '../../lib/utils/aria'
import type { WithTestId } from '../../lib/utils/testid'
import styles from './button.module.scss'
import { ButtonContent } from './content'
import { PRESETS } from './presets'
import type { CommonButtonProps } from './props'

/**
 *  Renders a link with the same design as a regular button.
 * To use with Next, try [this](https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component)
 */
export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
    (
        {
            'data-testid': testId,
            analyticsKey,
            analyticsProperties,
            children,
            className,
            containerElementId,
            download,
            href,
            loading,
            media,
            onClick,
            placement,
            preset = 'primary',
            referrerPolicy,
            rel,
            size,
            style,
            target,
            title,
            disabled,
            ...restOfProps
        },
        ref
    ) => {
        const presetStyles = PRESETS[preset]
        const {
            deviceStore: { os },
        } = useStores()

        return (
            <a
                {...ariaAttributes(restOfProps)}
                className={classNames(
                    styles.button,
                    presetStyles.button,
                    presetStyles[size],
                    disabled && styles.disabled,
                    className
                )}
                data-testid={testId}
                download={download}
                media={media}
                onClick={(e) => {
                    onClick?.(e)
                    tracker.clickButton(analyticsKey ?? title ?? '', {
                        os,
                        placement,
                        containerElementId,
                        ...analyticsProperties,
                    })
                }}
                href={href}
                ref={ref}
                referrerPolicy={referrerPolicy}
                rel={rel}
                style={style}
                title={title}
                target={target}
            >
                <ButtonContent>{children}</ButtonContent>
            </a>
        )
    }
)

export type LinkButtonProps = WithTestId<
    CommonButtonProps &
        Pick<
            AnchorHTMLAttributes<HTMLAnchorElement>,
            | 'href'
            | 'title'
            | 'children'
            | 'download'
            | 'media'
            | 'onClick'
            | 'referrerPolicy'
            | 'rel'
            | 'style'
            | 'target'
        > & {
            disabled?: boolean
            preset?: string
        }
>
