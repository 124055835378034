import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import React, { forwardRef } from 'react'

import type { OptionallyResponsiveProp } from '../../../../lib/utils/responsiveProps'
import { setupResponsivePropToClass } from '../../../../lib/utils/responsiveProps'
import styles from './column.module.scss'

const responsivePropToClass = setupResponsivePropToClass(styles)

export const Column = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    (
        { span = 1, offset = 0, push = 0, pull = 0, order, className, ...rest },
        ref
    ) => (
        <div
            ref={ref}
            className={classNames(
                styles.column,
                responsivePropToClass(span, (v) => `span-${v}`),
                responsivePropToClass(offset, (v) => `offset-${v}`),
                responsivePropToClass(push, (v) => `push-${v}`),
                responsivePropToClass(pull, (v) => `pull-${v}`),
                responsivePropToClass(order, (v) => `order-${v}`),
                className
            )}
            {...rest}
        />
    )
)

interface Props {
    /**
     * The `span` prop sets the number of columns that this element will span.
     */
    span?: OptionallyResponsiveProp<number>
    /**
     * The `offset` prop sets the number of columns that this element will be offset from the start of the Row or the previous element in the Row.
     */
    offset?: OptionallyResponsiveProp<number>
    /**
     * The `push` prop sets the number of columns to push the subsequent element in the Row.
     */
    push?: OptionallyResponsiveProp<number>
    /**
     * The `pull` prop sets the number of columns to pull the subsequent element in the Row.
     */
    pull?: OptionallyResponsiveProp<number>
    /**
     * Sets the CSS "order" property to the provided value
     */
    order?: OptionallyResponsiveProp<number>
    /**
     * Allow other classes to be passed to Column.
     */
    className?: string
}
