import classNames from 'classnames'
import { useSelect } from 'downshift'
import camelCase from 'lodash/camelCase'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import useMeasure from 'react-use-measure'
import ResizeObserver from 'resize-observer-polyfill'

import { tracker } from '../../../lib/store/tracker/useTracker'
import type { LocalLink } from '../../../shared/util/link'
import { Button } from '../../button/button'
import { Chevron } from '../../icons/chevron'
import { Text } from '../../text'
import styles from './dropdown-menu.module.scss'

export const DropdownMenu: FunctionComponent<
    React.PropsWithChildren<Props>
> = ({ id, label, items }) => {
    const router = useRouter()
    const initialSelectedItem = items.find(
        ({ url }: DropdownItem) => url.pathname === router.asPath
    )

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        closeMenu,
    } = useSelect({
        id,
        initialSelectedItem,
        items,
        onSelectedItemChange({ selectedItem }) {
            if (!selectedItem) return
            tracker.clickLink(
                `dropdown.${camelCase(label)}.${camelCase(selectedItem.text)}`
            )
            const { href, as } = selectedItem.url.asNextProps(router)

            router.push(href, as)
        },
    })

    const [contentRef, { height }] = useMeasure({ polyfill: ResizeObserver })

    const wrapperStyle = {
        pointerEvents: isOpen ? 'all' : 'none',
        opacity: isOpen ? 1 : 0,
        height: isOpen ? height : 0,
        overflow: 'hidden',
    } as const

    return (
        <div
            className={styles.content}
            onMouseEnter={openMenu}
            onMouseLeave={closeMenu}
        >
            <Button
                aria-expanded={isOpen}
                analyticsKey='dropdown.toggle'
                {...getToggleButtonProps()}
                preset='plain'
                animatePress={false}
                className={classNames(styles.trigger, {
                    [styles['trigger-open']]: isOpen,
                    [styles['trigger-selected']]: isOpen || initialSelectedItem,
                })}
                onClick={() => {
                    if (isOpen) {
                        closeMenu()
                    } else {
                        openMenu()
                    }
                }}
            >
                <label
                    className={styles['trigger-text']}
                    data-label={label}
                    {...getLabelProps()}
                >
                    <Text
                        tag='span'
                        typeStyle={{
                            _: 'body-2xs',
                            md: 'body-xs',
                        }}
                        bold
                    >
                        {label}
                    </Text>
                </label>
                <Chevron
                    animated={false}
                    direction={isOpen ? 'up' : 'down'}
                    height='6px'
                    color='currentColor'
                />
            </Button>

            <div className={styles.dropdown} style={wrapperStyle}>
                <div ref={contentRef} className={styles.inner}>
                    <div>
                        <ul className={styles.list} {...getMenuProps()}>
                            {items.map((item, index) => {
                                const { active, href, as } =
                                    item.url.asNextProps(router)

                                return (
                                    <li key={item.url.pathname}>
                                        <Link
                                            href={href}
                                            as={as}
                                            legacyBehavior
                                        >
                                            <a
                                                data-label={item.text}
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                                className={classNames(
                                                    styles.item,
                                                    {
                                                        [styles[
                                                            'item-highlighted'
                                                        ]]:
                                                            index ===
                                                            highlightedIndex,
                                                        [styles[
                                                            'item-selected'
                                                        ]]: active,
                                                    }
                                                )}
                                                {...getItemProps({
                                                    item,
                                                    index,
                                                    'aria-selected': active,
                                                    disabled: active,
                                                })}
                                            >
                                                <Text
                                                    tag='span'
                                                    typeStyle='body-xs'
                                                    bold
                                                >
                                                    {item.text}
                                                </Text>
                                            </a>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface DropdownItem {
    text: string
    url: LocalLink
}

interface Props {
    /** Downshift requires unique DOM id to distribute accessible identifiers properly. */
    id: string
    /** List of items. */
    items: DropdownItem[]
    /** Text on the toggle button. */
    label: string
}
