import type { ReactNode } from 'react'
import React from 'react'

/**
 * Clones it's children and applies a "data-theme" attribute to override the
 * default theme variables. The [data-theme] selectors are included in the
 * global CSS.
 *
 *
 * ```tsx
 * const content = <div>Hello, world!</div>
 * return withTheme(content)
 *
 * // Returns () => <div data-theme="cyanora">Hello, world!</div>
 * ```
 */
export const withTheme = (
    theme: { key: ThemeOptions } | ThemeOptions | string | undefined,
    content: ReactNode
) => {
    if (React.isValidElement(content)) {
        if (content.type && content.type === React.Fragment) {
            throw new Error('withTheme does not support Fragments.')
        }

        if (theme) {
            return React.Children.only(
                React.cloneElement(content, {
                    'data-theme': theme,
                })
            )
        }

        return content
    }

    throw new Error(
        'withTheme argument `content` is not a valid React element. You may have passed a primitive type or array as `content`.'
    )
}

export enum ThemeOptions {
    black = 'black',
    cyanora = 'cyanora',
    'future-fuscia' = 'future-fuscia',
    'coal-mine-canary' = 'coal-mine-canary',
    'apricot' = 'apricot',
    'amethyst' = 'amethyst',
    'sprinttime' = 'sprinttime',
}
