import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import React from 'react'

import { TEXT } from '../../../lib/utils/text'
import type { ThemeOptions } from '../../../lib/utils/withTheme'
import { withTheme } from '../../../lib/utils/withTheme'
import { Disclaimers } from '../../disclaimers'
import type { DisclaimersProps } from '../../disclaimers/disclaimers'
import { Container } from '../grid'
import { FOOTER_NAVIGATION_LINKS } from '../navigation-links'
import { FooterCta } from './footer-cta'
import { FooterLinks } from './footer-links'
import type { FooterLinksProps } from './footer-links'
import styles from './footer.module.scss'

/**
 * Global footer. Contains the {@link FooterCta} and {@link FooterLinks} components.
 */
export const Footer: FunctionComponent<FooterProps> = ({
    links = FOOTER_NAVIGATION_LINKS,
    theme,
    disclaimers,
}) => {
    const footerCTA = withTheme(theme, <FooterCta />)

    return (
        <footer
            data-testid='layout.footer'
            data-theme={theme}
            className={classNames(styles.footer, 'stairs--top-right')}
        >
            <Container>
                <div className={styles.inner}>
                    {footerCTA}
                    {!!links && <FooterLinks links={links} />}
                </div>
            </Container>

            <Disclaimers
                defaults={TEXT.footer.defaultDisclaimer}
                {...disclaimers}
            />
        </footer>
    )
}

export default Footer

export interface FooterProps extends FooterLinksProps {
    theme: ThemeOptions | string
    disclaimers?: DisclaimersProps
}
