import { animated } from '@react-spring/web'
import classNames from 'classnames'
import type {
    AriaAttributes,
    FunctionComponent,
    HTMLAttributes,
    ReactElement,
    ReactNode,
} from 'react'
import { isValidElement } from 'react'

import { useShakeAnimation } from '../../../lib/hooks/useShakeAnimation'
import { PRESETS } from './presets'

/**
 * Wrapper for all text-based inputs.
 */
export const Label: FunctionComponent<React.PropsWithChildren<LabelProps>> = ({
    preset = 'rounded',
    adornment,
    label,
    valid,
    error,
    size,
    validationCount,
    className,
    'data-testid': testId,
    fullWidth,
    children,
    ...restOfProps
}) => {
    const styles = PRESETS[preset]

    const shake = useShakeAnimation({ amplitude: size, enabled: !valid }, [
        validationCount,
    ])

    const [leftAdornment, rightAdornment] = isAdornment(adornment)
        ? [adornment, null]
        : [adornment?.left, adornment?.right]

    return (
        <label
            {...restOfProps}
            data-testid={testId}
            className={classNames(styles.wrapper, className, {
                [styles['full-width']]: fullWidth,
            })}
        >
            {label && (
                <div
                    className={classNames(styles.label, {
                        [styles['label-with-error']]: !valid,
                    })}
                >
                    {label}
                </div>
            )}

            <animated.div
                style={shake}
                className={classNames(
                    styles.container,
                    styles[`container-${size}`],
                    { [styles['container-with-error']]: !valid }
                )}
            >
                {leftAdornment && (
                    <div className={styles.adornment}>{leftAdornment}</div>
                )}

                {children}

                {rightAdornment && (
                    <div className={styles.adornment}>{rightAdornment}</div>
                )}

                {!valid && size === 'medium' && error && (
                    <div
                        role='alert'
                        className={styles.error}
                        data-testid='form.validation.error'
                    >
                        {error}
                    </div>
                )}
            </animated.div>
        </label>
    )
}

type Adornment = string | ReactElement

const isAdornment = (value?: LabelProps['adornment']): value is Adornment => {
    if (!value) return false
    if (typeof value === 'string') return true
    return isValidElement(value)
}

/** Core input for all text-based inputs */
export type LabelProps = AriaAttributes &
    Pick<HTMLAttributes<HTMLLabelElement>, 'data-testid' | 'className'> & {
        preset?: keyof typeof PRESETS

        /**
         * Something to prepend/append to the input. Currency, phone prefix, icon, etc. Left adornment by default.
         */
        adornment?: Adornment | { left?: Adornment; right?: Adornment }

        /**
         * Indicates how many time the field has been validated.
         */
        validationCount?: number

        /**
         * Size may impact font, padding, and/or border radius.
         */
        size: 'small' | 'medium'

        /**
         * Set to true if the field passed validation.
         */
        valid?: boolean

        /**
         * Error text for when the field is not valid.
         */
        error?: ReactNode

        /**
         * Help text.
         */
        label?: string
        /**
         * If true, the input will take up the full width of its container.
         */
        fullWidth?: boolean
    }
