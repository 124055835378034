import type { Amount } from '@getstep/sdk/dist/api/generated/CommonProto'

import { TEXT } from './text'

const locale = 'en-US'

/**
 * Formats a number as percentage.
 */
export function formatPercent(value: number) {
    return (value < 1 ? value : value / 100).toLocaleString(locale, {
        style: 'percent',
    })
}

/**
 * Formats an amount as human readable string.
 */
export function formatAmount(amount: Amount) {
    const { currency, value } = amount
    const number = parseFloat(value)
    if (Number.isInteger(number)) {
        return number.toLocaleString(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
        })
    }
    return number.toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
    })
}

/**
 * Forgiving version of formatAmount.
 * Takes value and currency, makes sure that they can represent a valid amount,
 * and returns a string with the currency symbol.
 *
 * @param value - The value of the amount. If not a numeric string, fallback will be used.
 * @param currency - The currency of the amount. If not present, fallback will be used.
 * @param fallback - The value to return if value and currency do not represent a valid amount.
 */
export function formatAsAmount(
    value?: unknown,
    currency?: unknown,
    fallback = TEXT.money as string
): string {
    if (typeof value !== 'string' || typeof currency !== 'string') {
        return fallback
    }

    if (value === '' || currency === '') return fallback

    if (Number.isNaN(+value)) return fallback

    try {
        return formatAmount({ value, currency })
    } catch (e) {
        return fallback
    }
}

/**
 * Converts a string to a number.
 */
export const stringToNumber = (value: string) => {
    const numberString = value.replace(/[^0-9.-]/g, '')

    return Number(numberString)
}

/**
 * Removes all non-digit characters from a string.
 */
export const digitsOnly = (value: string) => {
    return value.replace(/[^0-9]/g, '')
}
