import groupBy from 'lodash/fp/groupBy'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import type { FunctionComponent } from 'react'

import { Socials } from '../../socials'
import { Text } from '../../text'
import { Column, Flex, Row } from '../grid'
import type { NavigationLinks } from '../navigation-links'
import styles from './footer-links.module.scss'

const ConsentButton = dynamic(() => import('../../consent-button'), {
    ssr: false,
})

/**
 * Footer links are dynamic. The links are split up by category and the grid
 * column width for the social links resizes accordingly. It can comfortably
 * fit 3 categories on desktop and mobile, but any more gets a bit squished.
 */
export const FooterLinks: FunctionComponent<FooterLinksProps> = ({ links }) => {
    // Map the navigation links by category to [[ category, items[] ]]
    const linkCategories = Object.entries(groupBy('category', links))
    const totalCategories = linkCategories.length
    const linksColumnSpan = 2
    const socialsColumnSpan = 12 - linksColumnSpan * totalCategories

    return (
        <Row className={styles['links-row']}>
            {linkCategories.map(([category, items], categoryIndex) => (
                <Column
                    span={{
                        _: 12 / totalCategories,
                        md: linksColumnSpan,
                    }}
                    key={`${category}--${categoryIndex}`}
                >
                    <Text
                        typeStyle='body-sm'
                        tag='div'
                        className={styles.category}
                        bold
                    >
                        {category}
                    </Text>
                    <ul className={styles.links}>
                        {items.map(({ label, href }) => (
                            <li className={styles['link-item']} key={href}>
                                <Link href={href} passHref legacyBehavior>
                                    <Text
                                        tag='a'
                                        typeStyle='body-xs'
                                        color='dim'
                                    >
                                        {label}
                                    </Text>
                                </Link>
                            </li>
                        ))}
                        {category === 'Legal' && (
                            <li className={styles['link-item']}>
                                <ConsentButton />
                            </li>
                        )}
                    </ul>
                </Column>
            ))}

            {/* Social media icons */}
            <Column span={{ _: 8, md: socialsColumnSpan }}>
                <Flex justify={{ _: 'flex-start', md: 'flex-end' }}>
                    <Socials />
                </Flex>
            </Column>
        </Row>
    )
}

export interface FooterLinksProps {
    links?: NavigationLinks
}
