import type { Config } from '@getstep/sdk/dist/http/HttpClient'
import type { HttpError } from '@getstep/sdk/dist/http/HttpError'
import { mustExist } from '@getstep/sdk/dist/util/Assert'
import type { Method } from 'axios'
import type { IncomingMessage } from 'http'
import noop from 'lodash/noop'

import { extendConsole } from '../../shared/logging/console'
import { isDevelopment } from '../../shared/util'
import { inspectEnv, isBrowser } from '../../shared/util/env'

/**
 * Loads huge chunks of the SDK on demand
 */
export class SdkClientStore {
    private constructor(
        private request?: IncomingMessage,
        public teamEnvironment?: string
    ) {}

    get config(): Config {
        const { request, teamEnvironment } = this
        const baseURL = mustExist(
            process.env.NEXT_PUBLIC_STEP_API_BASE,
            `env.NEXT_PUBLIC_STEP_API_BASE base url not configured. ENV:\n${inspectEnv()}`
        )

        // forward client ip to api if call is being made during SSR
        const clientIp =
            request?.headers['x-forwarded-for'] ??
            request?.socket?.remoteAddress
        return {
            headers: clientIp ? { ['x-forwarded-for']: clientIp } : {},
            baseURL,
            metadata: {
                appVersion: process.env.npm_package_version ?? '',
                platform: 'web',
                bundleId: '',
                teamEnvironment: () => teamEnvironment,
            },
            logger: (() => {
                if (isDevelopment()) {
                    return devLogger
                }

                if (isBrowser()) {
                    return nullLogger
                }

                return undefined // default logger
            })(),
            basePathResolver: async (serviceName) => {
                // only user internal hostname during SSR
                if (isBrowser()) return
                // perform internal hostname lookup to verify that this is a valid hostname in current environment
                try {
                    const internalHost = `${serviceName}.default`
                    const { promises: dns } = await import('dns')
                    await dns.lookup(internalHost)
                    // DO NOT CHANGE THIS TO HTTPS
                    return `http://${internalHost}`
                } catch (e) {
                    console.warn(
                        'could not resolve internal host, probably running locally...'
                    )
                    return
                }
            },
        }
    }

    static create(request?: IncomingMessage, teamEnvironment?: string) {
        return new SdkClientStore(request, teamEnvironment)
    }

    async createHttpClient() {
        const { SdkHttpClientFactory } = await import(
            '@getstep/sdk/dist/http/SdkHttpClientFactory'
        )
        return SdkHttpClientFactory.instance(this.config)
    }

    async createStripeHttpClient() {
        const { StripeHttpClient } = await import(
            '@getstep/sdk/dist/http/StripeHttpClient'
        )
        return new StripeHttpClient({
            appVersion: process.env.npm_package_version ?? '',
            platform: 'web',
            bundleId: '',
            teamEnvironment: () => this.teamEnvironment,
        })
    }

    async createPublicApi() {
        const [{ PublicApi }, client] = await Promise.all([
            import('@getstep/sdk/dist/http/PublicApi'),
            this.createHttpClient(),
        ])

        return new PublicApi(client)
    }

    restore(state: SdkStoreState) {
        this.teamEnvironment = state.teamEnvironment
    }

    toJSON() {
        return { teamEnvironment: this.teamEnvironment }
    }
}

const nullLogger = {
    request: noop,
    response: noop,
    httpError: noop,
    error: noop,
}

const logURL = (url?: string) => {
    if (!url) return ''
    const { pathname, search } = new URL(url)
    return `${pathname}${search}`
}

const devLogger = {
    request(_?: string, method?: Method, url?: string, body?: unknown) {
        const logger = extendConsole(logURL(url))
        logger.debug(method?.toUpperCase(), '\n', body)
    },
    response(_?: string, method?: Method, url?: string, data?: unknown) {
        const logger = extendConsole(logURL(url))
        logger.debug('res', data)
    },
    httpError(_?: string, method?: Method, url?: string, error?: HttpError) {
        const logger = extendConsole(logURL(url))
        logger.error(method, url, error?.name, error?.message)
    },
    error(_: string) {
        const logger = extendConsole('sdk')
        logger.error('Unknown error in request')
    },
}

export interface SdkStoreState {
    teamEnvironment?: string
}
