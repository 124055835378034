import type { Feature } from '@getstep/sdk/dist/api/generated/FeatureProto'
import { getCookie, setCookie } from 'cookies-next'
import MurmurHash3 from 'imurmurhash'
import type { GetServerSidePropsContext } from 'next'
import type { ParsedUrlQuery } from 'querystring'
import { v4 } from 'uuid'

import { LocalLink } from '../../shared/util/link'
import { PAGE_OPTIONS } from '../utils/page'
import { ThemeMode } from '../utils/theme'

const { SPONSOR_REQUEST, PAY } = PAGE_OPTIONS

interface Route {
    query: ParsedUrlQuery
    pathname: string
    asPath?: string
}

/**
 * These features get set on user's account,
 * and are passed via query params to the links that this user shares with other people.
 *
 * For example, when WEB_SPONSOR_APPROVAL is enabled on teen's account,
 * their sponsor invite links will contain ?flow=web,
 * and the adults they will send the link to will be able to see the sponsor approval feature.
 */
export enum FeatureFromURL {
    WEB_SPONSOR_APPROVAL = 'WEB_SPONSOR_APPROVAL',
    EXPERIMENTAL = 'EXPERIMENTAL',
    P2P_FOR_NON_STEP_PERSONALIZED_UI = 'P2P_FOR_NON_STEP_PERSONALIZED_UI',
    P2P_FOR_NON_STEP_WEB_PAYMENT = 'P2P_FOR_NON_STEP_WEB_PAYMENT',
    APPSFLYER_LINKING = 'APPSFLYER_LINKING',
    FORCE_MODE = 'FORCE_MODE',
    EXPEDITED_ONBOARDING = 'EXPEDITED_ONBOARDING',
}

/**
 * Returns true if the feature is enabled for the url params
 */
export const featureFromParams = (
    feature: FeatureFromURL,
    { query, pathname, asPath }: Route
): boolean => {
    const matcher = matchers[feature]
    return matcher ? matcher({ query, pathname, asPath }) : false
}

export const featureFromOriginalURL = (
    feature: FeatureFromURL,
    originalUrl?: string
) => {
    if (!originalUrl) return false
    return featureFromParams(feature, new LocalLink({ base: originalUrl }))
}

const matchers = {
    [FeatureFromURL.WEB_SPONSOR_APPROVAL]: ({ pathname, query }: Route) =>
        query?.flow === 'web' &&
        pathname.replace(/\W/g, '') === SPONSOR_REQUEST.slug,

    [FeatureFromURL.P2P_FOR_NON_STEP_PERSONALIZED_UI]: ({ query }: Route) =>
        !!query?.tr,

    [FeatureFromURL.P2P_FOR_NON_STEP_WEB_PAYMENT]: ({
        pathname,
        query,
    }: Route) =>
        query?.flow === 'direct' && pathname.startsWith(`/${PAY.slug}`),

    [FeatureFromURL.EXPERIMENTAL]: ({ query }: Route) =>
        query?.dev_ui === 'experimental',

    [FeatureFromURL.FORCE_MODE]: (route: Route) =>
        (
            Object.values(ThemeMode) as (string | string[] | undefined)[]
        ).includes(route.query?.mode),

    [FeatureFromURL.EXPEDITED_ONBOARDING]: (route: Route) =>
        isOnCampus(route) && route.query?.v?.toString() === '2',
}

const hasReferralCode = ({ pathname, asPath, query }: Route, code: string) =>
    pathname?.toUpperCase()?.includes(`/${code}`) ||
    asPath?.toUpperCase()?.includes(`/${code}`) ||
    query?.code?.toString()?.toUpperCase() === code

const isOnCampus = (route: Route) => hasReferralCode(route, 'ONCAMPUS')

/**
 * getFeaturesFromUrl - gets all 'feature'props from url params
 *
 * Example: step.com?id=abc&feature=WEB_SPONSORSHIP:true&feature=WEB_FUNDING:variant1&notFeature=foo
 * Result: { WEB_SPONSORSHIP: true}
 */
export const getFeaturesFromUrl = (url: string): Record<string, boolean> => {
    const urlParams = new URLSearchParams(url.split('?')[1])
    const featureParams = Array.from(urlParams.entries())
        .filter(([key]) => key.startsWith('feature'))
        .map(([, value]) => value.split(':'))
        .map(([key, value]) => [key, value?.toLowerCase()])
        .filter(([, value]) => ['true', 'false'].includes(value))
        .map(([key, value]) => [key, value.toLowerCase() === 'true'])
        .reduce((acc, [key, value]) => {
            if (typeof key !== 'string') return acc
            return {
                ...acc,
                [key]: value,
            }
        }, {})

    return featureParams
}

export const generateId = () => {
    return v4()
}

export const meetsFeatureThreshold = (key: string, percentage: number) => {
    const hash = new MurmurHash3(key)
    const hashValue = hash.result()
    const threshold = Math.floor(hashValue / (0xffffffff / 100))

    return threshold < percentage
}

export const getControlOrVariant = async <T>(
    {
        control,
        variant,
        variantPercentage,
        featureName,
    }: {
        control: T
        variant: T
        variantPercentage: number
        featureName: Feature | string
    },
    context: GetServerSidePropsContext
) => {
    const STEP_ANON_COOKIE_NAME = 'step_anon_id'
    const COOKIE_AGE = 60 * 60 * 24 * 365 // 365 days

    const { req, res } = context

    let anonymousId = getCookie(STEP_ANON_COOKIE_NAME, { req, res })

    if (!anonymousId) {
        anonymousId = generateId()
        setCookie(STEP_ANON_COOKIE_NAME, anonymousId, {
            req,
            res,
            maxAge: COOKIE_AGE,
        })
    }

    if (typeof anonymousId !== 'string') {
        throw new Error('Anonymous ID is not a string')
    }

    const enabled = meetsFeatureThreshold(
        anonymousId + ':' + featureName,
        variantPercentage
    )

    return enabled ? variant : control
}
