import classNames from 'classnames'
import type { FunctionComponent } from 'react'

import { SocialButton } from '../social-button'
import styles from './socials.module.scss'

const socialTypes = [
    'Instagram',
    'Twitter',
    'TikTok',
    'Facebook',
    'LinkedIn',
    'Discord',
    'YouTube',
] as const

export const Socials: FunctionComponent<Props> = ({ className }) => {
    return (
        <div className={classNames(styles.socials, className)}>
            {socialTypes.map((type, index) => (
                <div className={styles.icon} key={`${type}--${index}`}>
                    <SocialButton type={type} width='100%' color='white' />
                </div>
            ))}
        </div>
    )
}

interface Props {
    className?: string
}
