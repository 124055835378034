import pickBy from 'lodash/pickBy'
import type { ParsedUrlQuery } from 'querystring'

import * as AppsFlyerSmartScript from '../../utils/appsflyer-onelink-smart-script-v2.7.3'
import type { SdkClientStore } from '../SdkClientStore'
import type { AppLinker } from './AppLinker'
import type { AppLinkData, AppLinkParameters } from './parameters'

export class AppsFlyerLinker implements AppLinker {
    name = 'AppsFlyer'

    constructor(private sdk: SdkClientStore) {}

    static appsFlyerParams(query: ParsedUrlQuery): Partial<AppLinkData> {
        // Only AppsFlyer links should leverage pid. So if pid is specified, we read and store
        // attribution link data via query params.
        if (query['pid']) {
            const params = Object.entries(afParameters).map(
                ([_key, value]) => value.keys[0]
            )
            const custom = afCustom.map((entry) => entry.paramKey)
            return pickBy(
                query,
                (value, key) => !!value && [...params, ...custom].includes(key)
            )
        }
        return {}
    }

    async init(): Promise<AppLinkParameters | void> {
        // do nothing, AppsFlyerSmartScript is loaded inline
    }

    link(params: AppLinkParameters): Promise<string | void> {
        const link = AppsFlyerSmartScript.generateOneLinkURL({
            oneLinkURL: process.env.NEXT_PUBLIC_APPSFLYER_ONELINK_URL,
            afParameters: {
                ...afParameters,
                afCustom: [
                    ...afCustom,
                    {
                        paramKey: 'code',
                        overrideValues: {},
                        keys: ['code'],
                        defaultValue: params.data.code,
                    },
                ],
            },
        })
        return Promise.resolve(
            link?.clickURL ??
                process.env.NEXT_PUBLIC_APPSFLYER_ORGANIC_DOWNLOAD_URL
        )
    }

    async sendSMS(
        phoneNumber: string,
        { data, templateId }: AppLinkParameters
    ): Promise<string | void> {
        const { parsePhoneNumber } = await import(
            '@getstep/sdk/dist/util/Phones'
        )

        const api = await this.sdk.createPublicApi()

        const phone = parsePhoneNumber(phoneNumber)

        if (phone.valid) {
            await api.sendDownloadSms(phone.digits, {
                templateId,
                partnerId: data.pid ?? '',
                campaign: data.c,
                campaignId: data.af_c_id,
                siteId: data.af_siteid,
                subSiteId: data.af_sub_siteid,
                clickId: data.clickid,
                clickLookback: data.af_click_lookback,
                stepConversionData: {
                    code: data.code,
                    segmentUserId: data.segment_user_id,
                },
            })
        }
        return undefined
    }
}

// Query params to forward, from the original incoming OneLink, to the linker OneLink.
// afParameters are explicit AppsFlyer defined: https://support.appsflyer.com/hc/en-us/articles/4413588932241#configuration-object
// afCustom are Step/AppsFlyer partner defined.
const afParameters = {
    mediaSource: {
        keys: ['source', 'utm_source', 'pid'],
        overrideValues: {},
        defaultValue: 'web_organic',
    },
    campaign: {
        keys: ['c', 'campaign', 'utm_campaign'],
        overrideValues: {},
    },
    ad: {
        keys: ['af_ad', 'utm_ad'],
        overrideValues: {},
    },
    adSet: {
        keys: ['af_adset'],
        overrideValues: {},
    },
    channel: {
        keys: ['af_channel', 'channel', 'utm_channel'],
        overrideValues: {},
    },
}
const afCustom = [
    {
        paramKey: 'sponsee_full_name',
        keys: ['sponsee_full_name'],
    },
    {
        paramKey: 'sponsee_first_name',
        keys: ['sponsee_first_name'],
    },
    {
        paramKey: 'sponsee_last_name',
        keys: ['sponsee_last_name'],
    },
    {
        paramKey: 'sponsor_full_name',
        keys: ['sponsor_full_name'],
    },
    {
        paramKey: 'sponsor_first_name',
        keys: ['sponsor_first_name'],
    },
    {
        paramKey: 'sponsor_last_name',
        keys: ['sponsor_last_name'],
    },
    {
        paramKey: 'admin_full_name',
        keys: ['admin_full_name'],
    },
    {
        paramKey: 'admin_first_name',
        keys: ['admin_first_name'],
    },
    {
        paramKey: 'admin_last_name',
        keys: ['admin_last_name'],
    },
    {
        paramKey: 'af_c_id',
        keys: ['af_c_id'],
    },
    {
        paramKey: 'af_siteid',
        keys: ['af_siteid'],
    },
    {
        paramKey: 'af_sub_siteid',
        keys: ['af_sub_siteid'],
    },
    {
        paramKey: 'clickid',
        keys: ['clickid'],
    },
    {
        paramKey: 'af_click_lookback',
        keys: ['af_click_lookback'],
    },
    {
        paramKey: 'af_ss_ui',
        defaultValue: 'true',
    },
]
