import type { ReactNode } from 'react'

import { Text } from '../text'
import styles from './tooltip.module.scss'

export const Tooltip: React.FunctionComponent<Props> = ({
    children,
    content,
    show = false,
}) => {
    return (
        <div className={styles.tooltip}>
            {children}
            {show && (
                <Text tag='div' typeStyle='body-2xs' className={styles.content}>
                    {content}
                </Text>
            )}
        </div>
    )
}

interface Props {
    show?: boolean
    content: ReactNode
    children: ReactNode
}
