import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import React, { forwardRef } from 'react'

import styles from './container.module.scss'

export const Container = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ className, style, ...rest }, ref) => (
        <div
            ref={ref}
            className={classNames(styles.container, className)}
            style={style}
            {...rest}
        />
    )
)

interface Props {
    /**
     * Allow other classes to be passed to Container.
     */
    className?: string
    style?: { backgroundColor: string }
    id?: string
}
