import type { Feature } from '@getstep/sdk/dist/api/generated/FeatureProto'
import type {
    FeatureOverride,
    FeatureOverridesStorage,
} from '@getstep/sdk/dist/store/features/FeatureOverridesStorage'
import { observable } from 'mobx'

import type { EnvironmentName } from '../../types/environment'
import { getFeaturesFromUrl, meetsFeatureThreshold } from '../utils/feature'
import type { AnalyticsStore } from './AnalyticsStore'
import {
    makePersistentObservable,
    PersistentStore,
} from './persistence/PersistentStore'

export class LocalFeatureOverridesStorage
    extends PersistentStore
    implements FeatureOverridesStorage
{
    static readonly className = 'LocalFeatureOverridesStorage'
    static readonly persistentFields = ['_featureOverrides']

    private _featureOverrides: FeatureValues = {}

    private clientSideFeatures: ClientFeatures = {}

    private constructor(private readonly analyticsStore: AnalyticsStore) {
        super()

        makePersistentObservable<LocalFeatureOverridesStorage>(this, {
            _featureOverrides: observable.deep,
        })
    }

    get featureOverrides(): FeatureValues {
        const { clientSideFeatures } = this
        const env = process.env.NEXT_PUBLIC_ENV ?? 'dev'

        // Collect the values of all the client-side features, based on current environment.
        const clientFeatureValues: FeatureValues = {}
        let feature: keyof typeof Feature
        for (feature in clientSideFeatures) {
            const config = clientSideFeatures[feature]

            clientFeatureValues[feature] = config?.[env]?.value
        }

        // Apply and return the feature overrides on top of the client-side feature values.
        return {
            ...clientFeatureValues,
            ...this._featureOverrides,
        }
    }

    static create(analytics: AnalyticsStore): LocalFeatureOverridesStorage {
        return new LocalFeatureOverridesStorage(analytics)
    }

    /**
     * Returns the overridden feature value. If a feature isn't overridden, it defaults to false.
     * @param feature to query whether it is enabled.
     */
    isEnabled(feature: keyof typeof Feature): boolean {
        return this.featureOverrides[feature] ?? false
    }

    isClientSide = (feature: Feature): boolean => {
        return feature in this.clientSideFeatures
    }

    hasOverride(feature: Feature): boolean {
        // Since this class provides the source of truth for client side features, these features
        // are always considered overridden.

        return (
            feature in this.clientSideFeatures ||
            feature in this._featureOverrides
        )
    }

    clearOverrides = (): void => {
        this._featureOverrides = {}
    }

    overrideFeature = (feature: Feature, value: FeatureOverride): void => {
        this._featureOverrides = { ...this._featureOverrides, [feature]: value }
    }

    async load(): Promise<void> {
        const { clientSideFeatures } = this

        if (typeof window !== 'undefined') {
            // load the analytics store first thing so we can get the anonymous id
            await this.analyticsStore.load()

            const overrides = getFeaturesFromUrl(window.location.href)

            if (overrides) {
                Object.entries(overrides).forEach(([feature, value]) => {
                    this.overrideFeature(feature as Feature, value)
                })
            }

            // Iterate over all client-side features, for all environments.
            let feature: keyof typeof Feature
            for (feature in clientSideFeatures) {
                const env = process.env.NEXT_PUBLIC_ENV ?? 'dev'
                const config = clientSideFeatures[feature]?.[env]

                const anonymousId = this.analyticsStore?.anonymousId
                // Compute whether feature would be enabled for this environment.
                if (config.value && anonymousId) {
                    config.value = meetsFeatureThreshold(
                        anonymousId + ':' + feature,
                        config.percent
                    )
                }
            }
        }
    }
}

type FeatureValues = Partial<Record<Feature, boolean>>

type ClientFeatures = { [key in Feature]?: ClientFeatureConfig }
type ClientFeatureConfig = Partial<
    Record<EnvironmentName, { percent: number; value?: boolean }>
>
