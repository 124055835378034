import classNames from 'classnames'
import type { ButtonHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { useFiniteAnimation } from '../../lib/hooks/useAnimation'
import { tracker } from '../../lib/store/tracker/useTracker'
import { useStores } from '../../lib/store/useStores'
import { ariaAttributes } from '../../lib/utils/aria'
import type { WithTestId } from '../../lib/utils/testid'
import styles from './button.module.scss'
import { ButtonContent } from './content'
import { PRESETS } from './presets'
import type { CommonButtonProps } from './props'

/**
 * A button. Just a button. Not a link, for that @see LinkButton.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            'data-testid': testId,
            analyticsKey,
            analyticsProperties,
            animatePress = true,
            children,
            className,
            containerElementId,
            disabled,
            loading,
            name,
            onClick,
            placement,
            preset = 'primary',
            role = 'button',
            size,
            style,
            title,
            type = 'button',
            ...restOfProps
        },
        ref
    ) => {
        const presetStyles = PRESETS[preset]
        const {
            deviceStore: { os },
        } = useStores()

        const [animationRef, play, animationStatus] = useFiniteAnimation(
            'stepButtonPress',
            { resetTimeout: 200 }
        )

        return (
            <button
                {...ariaAttributes(restOfProps)}
                className={classNames(
                    { [styles.disabled]: disabled },
                    styles.button,
                    presetStyles.button,
                    presetStyles[size],
                    presetStyles[animationStatus],
                    className
                )}
                data-testid={testId}
                disabled={disabled}
                name={name}
                onClick={(e) => {
                    if (animatePress) play()
                    tracker.clickButton(analyticsKey || title || '', {
                        os,
                        placement,
                        containerElementId,
                        ...analyticsProperties,
                    })
                    onClick?.(e)
                }}
                ref={ref}
                role={role}
                style={style}
                title={title}
                type={type}
            >
                <ButtonContent
                    ref={animationRef}
                    animationStatus={animationStatus}
                    loading={loading}
                >
                    {children}
                </ButtonContent>
            </button>
        )
    }
)

export type ButtonProps = WithTestId<
    CommonButtonProps &
        Pick<
            ButtonHTMLAttributes<HTMLButtonElement>,
            | 'title'
            | 'children'
            | 'disabled'
            | 'name'
            | 'onClick'
            | 'role'
            | 'style'
            | 'type'
        > & {
            /** Play special animation on press. True by default.  */
            animatePress?: boolean
        }
>
