import type { FunctionComponent } from 'react'

import { LINK } from '../../shared/util/link'
import { LinkButton } from '../button/link'
import * as ICONS from '../icons/social'
import type { IconProps } from '../icons/types'

/** Social network page button */
export const SocialButton: FunctionComponent<
    React.PropsWithChildren<Props>
> = ({ type, height, width, color, ...restOfProps }) => {
    const href = LINK.social[type]
    const Icon = ICONS[type]

    if (!href) return null

    return (
        <LinkButton
            size='medium'
            preset='anchor'
            key={type}
            href={href}
            target='_blank'
            rel='noopener noreferrer'
            title={type}
            {...restOfProps}
        >
            <Icon height={height} width={width} color={color} />
        </LinkButton>
    )
}

interface Props extends IconProps {
    type: keyof typeof ICONS
}
