import type {
    DeviceProvider,
    DeviceToken,
} from '@getstep/sdk/dist/api/generated/CommonProto'
import { sleep } from '@getstep/sdk/dist/util/Sleep'
import { getParser, OS_MAP, PLATFORMS_MAP } from 'bowser'
import type { IncomingMessage } from 'http'

import { extendConsole } from '../../shared/logging/console'
import { isPrd } from '../../shared/util/env'
import type { IsomorphicStore } from './IsomorphicStore'

const logger = extendConsole('DeviceStore')

/** Smatphone OS that we check for various reasons.
 * Null if it's not a smartphone at all. */
export type MobileOS = 'iOS' | 'Android' | 'Unsupported' | null

/** Home for the isomorphic user device data. */
export class DeviceStore implements IsomorphicStore<DeviceStoreState> {
    userAgent = ''

    /** Parses mobile OS from useragent string using `bowser`.
     * Useragent is available client-side via `navigator.useragent`
     * and server-side via `req?.headers?.['user-agent']`.*/
    get os(): MobileOS {
        const userAgent =
            typeof window !== 'undefined'
                ? window.navigator.userAgent
                : this.userAgent

        if (!userAgent || userAgent.includes('Snapchat')) return null

        const parser = getParser(userAgent)

        if (!parser.is(PLATFORMS_MAP.mobile)) return null
        if (parser.is(OS_MAP.iOS)) return 'iOS'
        if (parser.is(OS_MAP.Android)) return 'Android'
        return 'Unsupported'
    }

    /**
     * Returns true if we're on iOS or Android.
     */
    get mobile() {
        return this.os === 'iOS' || this.os === 'Android'
    }

    static create(req?: IncomingMessage) {
        const store = new DeviceStore()
        const userAgent = req?.headers['user-agent']
        if (userAgent) store.userAgent = userAgent
        return store
    }

    /**
     * Uses iovation to get a device token.
     */
    static fingerprint = async (): Promise<DeviceToken | undefined> => {
        if (!isPrd()) {
            const { deviceToken } = await import(
                '@getstep/sdk/dist/test/ApiFactory'
            )
            return deviceToken()
        }

        return new Promise(async (resolve) => {
            // Allow 4 attempts to get device fingerprint
            let attempt = 0
            while (++attempt <= 4) {
                const { IGLOO } = window
                // check that iovation has been initialized
                if (IGLOO?.getBlackbox) {
                    const fingerprint = IGLOO.getBlackbox()
                    if (fingerprint.finished) {
                        resolve({
                            token: fingerprint.blackbox,
                            provider: 'IOVATION' as DeviceProvider,
                        })
                        return
                    }
                }
                await sleep(100)
            }
            logger.error('failed to get device token')
            resolve(undefined)
        })
    }

    restore(state: DeviceStoreState) {
        this.userAgent = state.userAgent
    }

    toJSON() {
        return { userAgent: this.userAgent }
    }
}

export interface DeviceStoreState {
    userAgent: string
}
