import { IntercomScreen } from '@getstep/sdk/dist/store/intercom/Screens'
import type { DependencyList, FunctionComponent } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'

import type { DevAction, DevLink } from './ui/dev-menu'
import { devMenuStore } from './ui/dev-menu'
import { helpCenterStore } from './ui/help-center'
import { modalStore } from './ui/modal'
import { progressStore } from './ui/progress'
import { snackbarStore } from './ui/snackbar'
import { webviewStore } from './ui/webview'

export const ui = {
    // TODO (@cataline): remove this wrapper store
    snackbar: snackbarStore,
    modal: modalStore,
    progress: progressStore,
    devMenu: devMenuStore,
    helpCenter: helpCenterStore,
    webview: webviewStore,
}

export const UIContext = createContext<typeof ui>(ui)

interface Props {
    children: React.ReactNode
}

// TODO (@cataline): remove this
export const UIContextProvider: React.FC<Props> = ({ children }) => {
    const [store] = useState(() => ui)
    return <UIContext.Provider value={store}>{children}</UIContext.Provider>
}

/** Store for global UI element states. */
export const useUIStore = () => useContext(UIContext)

export const useDevMenuItem = (
    item: DevLink | DevAction,
    deps: DependencyList = []
) => {
    const { devMenu } = useUIStore()

    useEffect(() => {
        devMenu.add(item)
        return () => devMenu.remove(item)
    }, deps)
}

export const useHelpCenterScreen = (screen: IntercomScreen) => {
    const { helpCenter } = useUIStore()

    useEffect(() => {
        helpCenter.setScreen(screen)
        return () => helpCenter.setScreen(IntercomScreen.HOME)
    }, [screen])
}

export const HelpCenterScreenWhenInView: FunctionComponent<{
    screen: IntercomScreen
    children: React.ReactNode
}> = ({ screen, children }) => {
    const { helpCenter } = useUIStore()

    return (
        <InView onChange={() => helpCenter.setScreen(screen)}>
            {children}
        </InView>
    )
}
