import { observer } from 'mobx-react'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'

import { useStores } from '../../lib/store/useStores'
import { TEXT } from '../../lib/utils/text'
import { LinkButton } from '../button/link'

export const GetStepButton: FunctionComponent<Props> = observer(
    ({ size = 'medium' }) => {
        const {
            linkingStore: { appDownloadLink },
        } = useStores()

        const link = useMemo(() => {
            return (
                appDownloadLink ?? process.env.NEXT_PUBLIC_ORGANIC_DOWNLOAD_URL
            )
        }, [appDownloadLink])

        return (
            <LinkButton
                preset='primary'
                size={size}
                placement='section'
                title={TEXT.getStep}
                analyticsKey='getApp.button'
                data-testid='getApp.button'
                href={link}
            >
                {TEXT.getStep}
            </LinkButton>
        )
    }
)

interface Props {
    size?: 'small' | 'medium'
}
