import classnames from 'classnames'
import type { CSSProperties, FunctionComponent, HTMLAttributes } from 'react'

import { useAnchorRef } from '../../lib/hooks/useAnchorRef'
import { Skeleton } from '../skeleton'
import styles from './heading.module.scss'

/** Displays a heading, accepts all the usual `h1`-`h6` props.
 * Currently comes in two designs — primary (`h1`) and secondary (`h2`).
 * If there's other levels, don't forget to add styles for them.
 *
 * Note that using `h5` - `h6` (for example, if you're rendering Markdown) won't give you an error,
 * but also won't have any styling, so be careful there.
 *   */
export const Heading: FunctionComponent<React.PropsWithChildren<Props>> = ({
    children,
    loading,
    level,
    anchor,
    textAlign,
    className,
    ...restOfProps
}) => {
    const ref = useAnchorRef()
    const Tag = `h${level}` as HeadingTag

    return (
        <Tag
            id={anchor}
            ref={ref}
            className={classnames(
                styles.heading,
                styles[`heading-${level}`],
                className,
                {
                    [styles[`text-align-${textAlign}`]]: textAlign,
                    [styles['scroll-anchor']]: anchor,
                }
            )}
            {...restOfProps}
        >
            {loading ? <HeadingSkeleton level={level} /> : children}
        </Tag>
    )
}

const HeadingSkeleton: FunctionComponent<React.PropsWithChildren<Props>> = ({
    level,
}) => (
    <Skeleton
        className={classnames(
            styles.heading,
            styles.skeleton,
            styles[`skeleton-${level}`]
        )}
    />
)

const LEVELS = [1, 2, 3, 4, 5, 6] as const
export type HeadingLevel = (typeof LEVELS)[number]
export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface Props extends Omit<HTMLAttributes<HTMLHeadingElement>, 'className'> {
    /** Each page should have no more and no less than one `level: 1` heading. */
    level: HeadingLevel
    /** Extra classname for extra special cases. */
    className?: string
    /** Is it loading? */
    loading?: boolean
    /** Link within page */
    anchor?: string
    /** Sets the horizontal text alignment. */
    textAlign?: CSSProperties['textAlign']
}
